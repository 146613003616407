import * as echarts from 'echarts'
// 客户台量及统计
export const customerCurveLoadEcharts = (box, dataList, type) => {
  const myChart = echarts.init(document.getElementById(box))
  console.log(box, dataList, type);
  var option
  var data = []
  var data1 = []

  console.log(dataList)

  dataList.data.forEach(item => {
    data.push({
      value: type === 1 ? item.orderNum : item.totalSettableAmt,
      ...item
    })
    data1.push({
      value: type === 1 ? item.vehicleNum : item.totalFreight,
      ...item
    })
  })
  const legendData = type === 1 ? [ '台量','订单量'] : ['合同金额', '未结算金额']
  option = {
    title: {
      text: '客户年度台量及运费统计' 
    },
    legend: {
      data: legendData
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      }
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    grid: {
      width: '85%',
      height: '80%',
      left: '10%',
      bottom: '10%',
      right: '5%'
    },
    xAxis: {
      type: 'category',
      boundaryGap: ['20%', '20%'],
      data: dataList.dateTime
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        color: '#1656a3',
        formatter: function (v) {
          return v
        }
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: '#42a4ff'
        }
      },
      splitLine: {
        lineStyle: {
          color: '#42a4ff'
        }
      }
    },
    series: [
      {
        name: type === 1 ? '台量' : '合同金额',
        type: 'bar',
        smooth: true,
        lineStyle: {
          width: 0
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgb(128, 255, 165)'
            },
            {
              offset: 1,
              color: 'rgb(1, 191, 236)'
            }
          ])
        },
        emphasis: {
          focus: 'series'
        },
        data: data1
      },
      {
        name: type === 1 ? '订单量' : '未结算金额',
        type: 'bar',
        smooth: true,
        lineStyle: {
          width: 0
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgb(255, 191, 0)'
            },
            {
              offset: 1,
              color: 'rgb(224, 62, 76)'
            }
          ])
        },
        emphasis: {
          focus: 'series'
        },
        data: data
      }
    ]
  }
  myChart.off('resize')
  window.addEventListener('resize', function () {
    myChart.resize()
  })
  option && myChart.setOption(option)
}
