import {
  postBodyRequest
} from '@/utils/axios'

export function getDataPage (params) {
  return postBodyRequest('/anal/customer/statistics/page', params)
}

export function customerCurve (params) {
  return postBodyRequest('/anal/customer/statistics/curve', params)
}
